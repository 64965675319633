import React from "react";
import Icon from "@ant-design/icons";

const VehicleSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 65.305 44.438"
    stroke="currentColor"
    fill="none"
  >
    <g id="vehicle" transform="translate(1 1)">
      <path
        id="Path_929"
        data-name="Path 929"
        d="M-20993.406-17270.676h63.3V-17286l-7.107-4.807h-47.826l-8.371,4.807Z"
        transform="translate(20993.406 17303.971)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_930"
        data-name="Path 930"
        d="M-20991.7-17280.525l7.174-13.172h34.643l7.342,13.172Z"
        transform="translate(20999.473 17293.697)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect
        id="Rectangle_924"
        data-name="Rectangle 924"
        width="9.133"
        height="9.133"
        transform="translate(1.855 33.304)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <g
        id="Rectangle_926"
        data-name="Rectangle 926"
        transform="translate(24.688 21.433)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <rect width="14.156" height="3.653" stroke="none" />
        <rect x="-1" y="-1" width="16.156" height="5.653" fill="none" />
      </g>
      <g
        id="Rectangle_927"
        data-name="Rectangle 927"
        transform="translate(9.169 21.433)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <rect width="4.567" height="3.653" stroke="none" />
        <rect x="-1" y="-1" width="6.567" height="5.653" fill="none" />
      </g>
      <g
        id="Rectangle_928"
        data-name="Rectangle 928"
        transform="translate(49.804 21.433)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <rect width="4.567" height="3.653" stroke="none" />
        <rect x="-1" y="-1" width="6.567" height="5.653" fill="none" />
      </g>
      <rect
        id="Rectangle_925"
        data-name="Rectangle 925"
        width="9.133"
        height="9.133"
        transform="translate(52.088 33.304)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const VehicleIcon = (props: any) => (
  <Icon component={VehicleSVG} {...props} />
);

export default VehicleIcon;
